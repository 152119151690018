/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdatedAppointment = {
    id: string;
    clinicId?: string;
    date?: string;
    type?: UpdatedAppointment.type;
    comment?: string;
    customerId?: string;
    doctorId?: string;
    duration?: number;
    petId?: string;
    visitReasonDetails?: string;
    illnessDuration?: string;
    treatmentId?: string;
};

export namespace UpdatedAppointment {

    export enum type {
        APPOINTMENT = 'appointment',
        INTERNAL = 'internal',
        BLOCKER_EXPIRING = 'blocker_expiring',
        BREAK = 'break',
        MEETING = 'meeting',
    }


}

