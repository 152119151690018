/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateCustomerAdminDto = {
    id: string;
    address1?: string;
    address2?: string;
    landlineNumber?: string | null;
    city?: string;
    country?: string;
    email?: string | null;
    firstName?: string;
    language?: UpdateCustomerAdminDto.language;
    lastName?: string;
    phoneNumber?: string | null;
    postalCode?: string;
    refusedEmail?: boolean;
    state?: string;
    preferredDocId?: string;
    source?: UpdateCustomerAdminDto.source;
};

export namespace UpdateCustomerAdminDto {

    export enum language {
        EN = 'en',
        DE = 'de',
    }

    export enum source {
        MYREX = 'myrex',
        REXOS = 'rexos',
        WALKIN = 'walkin',
        MYREX_WALKIN = 'myrex_walkin',
        REXAPP = 'rexapp',
        REXAPP_WALKIN = 'rexapp_walkin',
    }


}

