/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AppointmentModel } from '../models/AppointmentModel';
import type { AppointmentsExpirationDto } from '../models/AppointmentsExpirationDto';
import type { AppointmentsExpirationRo } from '../models/AppointmentsExpirationRo';
import type { CancelAppointmentDto } from '../models/CancelAppointmentDto';
import type { ConfirmAppointmentsDto } from '../models/ConfirmAppointmentsDto';
import type { GetAppointmentsForDashboardRo } from '../models/GetAppointmentsForDashboardRo';
import type { GetAppointmentWithCalculationsRo } from '../models/GetAppointmentWithCalculationsRo';
import type { GetIcsRo } from '../models/GetIcsRo';
import type { LockFromMedicalEventDto } from '../models/LockFromMedicalEventDto';
import type { RemindAppointmentsDto } from '../models/RemindAppointmentsDto';
import type { RemindRo } from '../models/RemindRo';
import type { RemindTelemedicineDto } from '../models/RemindTelemedicineDto';
import type { ReScheduleAppointmentDto } from '../models/ReScheduleAppointmentDto';
import type { ReScheduleAppointmentRo } from '../models/ReScheduleAppointmentRo';
import type { ScheduleMyrexWalkinsDto } from '../models/ScheduleMyrexWalkinsDto';
import type { ScheduleMyrexWalkinsRo } from '../models/ScheduleMyrexWalkinsRo';
import type { TriggerUpdateDto } from '../models/TriggerUpdateDto';
import type { UpdateAppointmentDto } from '../models/UpdateAppointmentDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AppointmentService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns ScheduleMyrexWalkinsRo
     * @throws ApiError
     */
    public appointmentControllerScheduleWalkin({
        requestBody,
    }: {
        requestBody: ScheduleMyrexWalkinsDto,
    }): CancelablePromise<ScheduleMyrexWalkinsRo> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/appointment/schedule-walkin',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns GetAppointmentWithCalculationsRo
     * @throws ApiError
     */
    public appointmentControllerGetOneWithCalculations({
        appointmentId,
    }: {
        appointmentId: string,
    }): CancelablePromise<GetAppointmentWithCalculationsRo> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/appointment/single-with-calculations',
            query: {
                'appointmentId': appointmentId,
            },
        });
    }

    /**
     * @returns AppointmentModel
     * @throws ApiError
     */
    public appointmentControllerConfirm({
        requestBody,
    }: {
        requestBody: ConfirmAppointmentsDto,
    }): CancelablePromise<Array<AppointmentModel>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/appointment/confirm',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns AppointmentModel
     * @throws ApiError
     */
    public appointmentControllerUpdate({
        requestBody,
    }: {
        requestBody: UpdateAppointmentDto,
    }): CancelablePromise<AppointmentModel> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v2/appointment/update',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns AppointmentModel
     * @throws ApiError
     */
    public appointmentControllerCancel({
        requestBody,
    }: {
        requestBody: CancelAppointmentDto,
    }): CancelablePromise<AppointmentModel> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v2/appointment/cancel',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ReScheduleAppointmentRo
     * @throws ApiError
     */
    public appointmentControllerReschedule({
        requestBody,
    }: {
        requestBody: ReScheduleAppointmentDto,
    }): CancelablePromise<ReScheduleAppointmentRo> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/appointment/reschedule',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns GetAppointmentsForDashboardRo
     * @throws ApiError
     */
    public appointmentControllerGetForDashboard({
        petId,
    }: {
        petId?: string,
    }): CancelablePromise<GetAppointmentsForDashboardRo> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/appointment/dashboard',
            query: {
                'petId': petId,
            },
        });
    }

    /**
     * @returns AppointmentModel
     * @throws ApiError
     */
    public appointmentControllerGetOne({
        appointmentId,
    }: {
        appointmentId: string,
    }): CancelablePromise<AppointmentModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/appointment/{appointmentId}',
            path: {
                'appointmentId': appointmentId,
            },
        });
    }

    /**
     * @returns GetIcsRo
     * @throws ApiError
     */
    public appointmentControllerGetIcs({
        appointmentId,
    }: {
        appointmentId: string,
    }): CancelablePromise<GetIcsRo> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/appointment/ics/{appointmentId}',
            path: {
                'appointmentId': appointmentId,
            },
        });
    }

    /**
     * @returns AppointmentModel
     * @throws ApiError
     */
    public appointmentControllerLockFromMedicalEvent({
        requestBody,
    }: {
        requestBody: LockFromMedicalEventDto,
    }): CancelablePromise<AppointmentModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/appointment/lock-from-medical-event',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns RemindRo
     * @throws ApiError
     */
    public appointmentTriggerControllerRemind(): CancelablePromise<RemindRo> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/appointment/trigger/cron/remind',
        });
    }

    /**
     * @returns RemindRo
     * @throws ApiError
     */
    public appointmentTriggerControllerRemindXMinsBefore({
        requestBody,
    }: {
        requestBody: RemindAppointmentsDto,
    }): CancelablePromise<RemindRo> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/appointment/trigger/cron/remind-appointments-x-mins-before',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns RemindRo
     * @throws ApiError
     */
    public appointmentTriggerControllerRemindTelemedicine({
        requestBody,
    }: {
        requestBody: RemindTelemedicineDto,
    }): CancelablePromise<RemindRo> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/appointment/trigger/cron/remind-telemedicine',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns AppointmentsExpirationRo
     * @throws ApiError
     */
    public appointmentTriggerControllerUpdateAllExpiredAppointments({
        requestBody,
    }: {
        requestBody: AppointmentsExpirationDto,
    }): CancelablePromise<AppointmentsExpirationRo> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/appointment/trigger/cron/update-all-expired-appointments',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public appointmentTriggerControllerUpdateAllCompletedAppointments(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/appointment/trigger/cron/update-completed',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public appointmentTriggerControllerHandleTreatmentUpdate({
        requestBody,
    }: {
        requestBody: TriggerUpdateDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/appointment/trigger/event/treatment',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public appointmentTriggerControllerHandleInstructionsUpdate({
        requestBody,
    }: {
        requestBody: TriggerUpdateDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/appointment/trigger/event/instructions',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public appointmentTriggerControllerTriggerEmailForUpdatedTelemedicineAppointments({
        requestBody,
    }: {
        requestBody: TriggerUpdateDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/appointment/trigger/event/handle-updated-appointment-email',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public appointmentTriggerControllerTriggerEmailForUpdatedAppointmentsSms({
        requestBody,
    }: {
        requestBody: TriggerUpdateDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/appointment/trigger/event/handle-updated-appointments-sms',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public appointmentTriggerControllerUpdatePreferredOptions({
        requestBody,
    }: {
        requestBody: TriggerUpdateDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/appointment/trigger/event/update-preferred-options',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
