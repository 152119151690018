/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateDoctorDto = {
    name?: string;
    email?: string;
    clinic_id?: string;
    role?: UpdateDoctorDto.role;
};

export namespace UpdateDoctorDto {

    export enum role {
        GOD = 'god',
        MANAGER = 'manager',
        CUSTOMER = 'customer',
        ANONYMOUS = 'anonymous',
        DOC = 'doc',
        ASSISTANT = 'assistant',
    }


}

