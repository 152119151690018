/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateDoctorDto = {
    name: string;
    email: string;
    clinicIds: Array<string>;
    treatmentIds: Array<string>;
    languages: Array<string>;
    role: CreateDoctorDto.role;
    createdAt?: string;
    userId?: string;
};

export namespace CreateDoctorDto {

    export enum role {
        GOD = 'god',
        MANAGER = 'manager',
        CUSTOMER = 'customer',
        ANONYMOUS = 'anonymous',
        DOC = 'doc',
        ASSISTANT = 'assistant',
    }


}

