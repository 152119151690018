/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AppointmentDto = {
    clinicId: string;
    dateTime: string;
    type: AppointmentDto.type;
    comment?: string;
    customerId?: string;
    doctorId?: string;
    duration?: number;
    petId?: string;
    source?: AppointmentDto.source;
    treatmentId: string;
};

export namespace AppointmentDto {

    export enum type {
        APPOINTMENT = 'appointment',
        INTERNAL = 'internal',
        BLOCKER_EXPIRING = 'blocker_expiring',
        BREAK = 'break',
        MEETING = 'meeting',
    }

    export enum source {
        MYREX = 'myrex',
        REXOS = 'rexos',
        WALKIN = 'walkin',
        MYREX_WALKIN = 'myrex_walkin',
        REXAPP = 'rexapp',
        REXAPP_WALKIN = 'rexapp_walkin',
    }


}

