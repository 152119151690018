import { AppointmentModel } from 'service/api';
import { TFunction } from 'react-i18next';

export function getAppointmentTypeOptions(t: TFunction<'common'>) {
  return [
    {
      value: AppointmentModel.type.APPOINTMENT,
      label: t(AppointmentModel.type.APPOINTMENT),
      manager: true,
    },
    {
      value: AppointmentModel.type.INTERNAL,
      label: t(AppointmentModel.type.INTERNAL),
      manager: true,
    },
    {
      value: AppointmentModel.type.BREAK,
      label: t(AppointmentModel.type.BREAK),
      manager: true,
    },
    {
      value: AppointmentModel.type.MEETING,
      label: t(AppointmentModel.type.MEETING),
      manager: true,
    },
    {
      value: AppointmentModel.type.BLOCKER_EXPIRING,
      label: t(AppointmentModel.type.BLOCKER_EXPIRING),
      manager: true,
    },
  ];
}
